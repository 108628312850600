@import "./mixins.scss";

.top-border {
  border-top: 7px solid #ed4bb6;
}

.header {
  background-image: url(./assets/images/home-bg1.png);
  background-repeat: no-repeat;
  height: 600px;
  background-size: cover;
  padding-top: 1em;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding-top: 2em;
  }

  .home-text {
    margin-top: 2em;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      padding-top: 5em;
    }

    .normal-text {
      margin-top: 0.5em;
    }
  }
}

h2,
h5 {
  color: white;
}
