@import "../../mixins.scss";
.approach-container {
  background-color: #f3efff;

  b {
    font-weight: 700;
  }

  .approach-bg {
    background: url(../../assets/images/framework.png) center center no-repeat;
    background-position-y: -30px;
    background-position-x: 270px;
    background-size: 50%;

    @include media-breakpoint-up(xl) {
      background-position-x: 315px;
      background-position-y: 0;
    }

    @include media-breakpoint-down(lg) {
      background-position-x: 170px;
      background-position-y: 0;
    }
    @include media-breakpoint-down(md) {
      background-position-x: center;
      padding-top: 17rem;
      background-size: 90%;
      background-position-y: -1rem;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    .col {
      // padding-left: 1rem;
      @include media-breakpoint-down(md) {
        // padding-left: 0;
      }
      h1 {
        font-weight: bold;
      }

      .col {
        width: 55%;
        @include media-breakpoint-down(md) {
          width: 100%;
          padding: 0 3rem;
          text-align: center;
        }
      }

      .advise {
        padding-top: 27%;
        @include media-breakpoint-down(md) {
          padding-top: 3rem;
        }
      }

      .right-column {
        padding-left: 50%;
        padding-top: 38%;
        @include media-breakpoint-down(md) {
          padding-left: 0;
          padding-top: 2rem;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
