@import "../../mixins.scss";

.offer {
  h2,
  h6 {
    color: white;
  }
}

.offer-container {
  .accord-conttainer {
    width: 94%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .accordion-body {
      padding-left: 0;
      b {
        color: #54499d;
      }
      .row {
        @include media-breakpoint-down(md) {
          flex-wrap: wrap-reverse;
        }

        .service-image {
          width: 100%;
          @include media-breakpoint-down(lg) {
            margin-bottom: 1rem;
          }
        }

        .col-md-5 {
          @include media-breakpoint-down(md) {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }

  .accordion-item {
    border-left: 0;
    border-right: 0;
  }

  .accordion-button {
    cursor: pointer;
    @include media-breakpoint-down(md) {
      padding: 1rem 0.5rem;
    }
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent;
  }

  .ba-button {
    @include round-button;
    border-color: #ba5397;
    color: #ba5397;
    margin: 2rem 0;
    margin-left: 0.8rem;
  }
  .accordion-body a {
    color: #ba5397;
    cursor: pointer;
  }
  .accordion-button {
    color: #54499d !important;
  }
  .home-sub-title {
    font-size: 14px;
  }
}

.accordion-flush .accordion-item:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.accordian-height {
  height: 50px;
}
.accordion-button {
  padding-left: 11px;
  font-size: 1.2rem;
}
