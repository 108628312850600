@import "../../mixins.scss";

.home-wrapper {
  position: relative;
  overflow: hidden;
  .explore {
    background-image: url(../../assets/images/explore.png);
    background-repeat: no-repeat;
    background-position: center center;
    width: 100px;
    height: 100px;
    position: absolute;
    background-size: contain;
    position: absolute;
    bottom: 50px;
    left: calc(50% - 50px);

    @include media-breakpoint-down(md) {
      left: calc(50% - 40px);
    }
    cursor: pointer;
  }
}
.home-container {
  overflow: hidden;
  position: relative;
  height: 610px;

  @include media-breakpoint-down(lg) {
    height: 390px;
  }
  @include media-breakpoint-down(md) {
    height: 700px;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -9;
    @include media-breakpoint-down(md) {
      left: -35rem;
    }
  }

  .oculus {
    @include media-breakpoint-down(md) {
      left: -45rem;
    }
  }

  .img-fluid {
    @include media-breakpoint-down(md) {
      height: 100% !important;
      max-width: initial !important;
    }
  }

  h1 {
    color: white;
    font-weight: bold;
  }

  h4 {
    font-size: 16px;
    color: white;
  }

  .slider-headings {
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }
}
.react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
  display: none;
}
.react-slideshow-container + ul.indicators {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 20px;

  @include media-breakpoint-down(md) {
    right: 30px;
  }
}

.each-slideshow-indicator {
  background: #fff !important;
  color: white !important;
}
