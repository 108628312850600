@import "../../mixins.scss";
.navbar {
  .talk {
    width: 70%;
  }

  .logo {
    width: 45%;

    @include media-breakpoint-only(lg) {
      width: 50%;
    }
  }

  .navbar-toggler {
    position: absolute;
    right: 10px;
    border: none;
    top: 10px;
    outline: none;
    z-index: 99;
    &:focus {
      box-shadow: none;
    }
  }

  .nav-item {
    font-weight: 300;
    @include media-breakpoint-down(lg) {
      padding: 0.5rem 0;
    }
  }

  .nav-item a {
    @include media-breakpoint-down(sm) {
      font-size: 35px;
    }
    @include media-breakpoint-down(md) {
      font-size: 35px;
    }
  }

  .nav-item a:hover {
    color: #ba5397 !important;
    text-decoration: none;
  }

  .link-active {
    color: #ba5397;
  }
}
// .navbar-collapse {
//   @include media-breakpoint-down(lg) {
//     background: #54499ded;
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//   }
// }

.offcanvas-collapse {
  @include media-breakpoint-down(lg) {
    position: fixed;
    background: url(../../assets/images/favicon.png) no-repeat 1rem 1rem;
    background-size: 2.5rem;
    top: 0; /* Height of navbar */
    right: 0;
    width: 100%;
    height: 150%;
    padding-top: 3rem;
    background-color: #54499d; // var(--gray-dark);
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
.offcanvas-collapse.open {
  @include media-breakpoint-down(lg) {
    -webkit-transform: translateX(0);
    transform: translateX(0); /* Account for horizontal padding on navbar */
  }
}

.top-fixed {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
}

.navbar-light .navbar-toggler-icon {
  background-image: url(../../assets/images/ham.svg) !important;
}

// .nav-scroller {
//   position: relative;
//   z-index: 2;
//   height: 2.75rem;
//   overflow-y: hidden;
// }

// .nav-scroller .nav {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -ms-flex-wrap: nowrap;
//   flex-wrap: nowrap;
//   padding-bottom: 1rem;
//   margin-top: -1px;
//   overflow-x: auto;
//   color: rgba(255, 255, 255, 0.75);
//   text-align: center;
//   white-space: nowrap;
//   -webkit-overflow-scrolling: touch;
// }
