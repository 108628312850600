// 211666
@import "../../mixins.scss";
.technologies-container {
  padding: 3rem;
  padding-top: 8.5rem;

  @include content-page-header;
  background-image: url(../../assets/images/technologies.png);
}

.technologies-content {
  background-color: white;
  margin-top: 1rem;
  .left-block {
    h3 {
      color: #54499d;
      font-size: 1.25rem;
    }
  }

  .tech-grid {
    .col {
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .icon {
      height: 8.5rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .separator {
      margin-top: 1.5rem;
    }
  }
  .grid-1 {
    .icon-1 {
      background-image: url(../../assets/images/angular.png);
    }
    .icon-2 {
      background-image: url(../../assets/images/react.png);
    }
    .icon-3 {
      background-image: url(../../assets/images/vue.png);
    }
    .icon-4 {
      background-image: url(../../assets/images/html.png);
    }
    .icon-5 {
      background-image: url(../../assets/images/bootstrap.png);
    }
    .icon-6 {
      background-image: url(../../assets/images/d3.png);
    }
  }

  .grid-2 {
    .icon-1 {
      background-image: url(../../assets/images/node.png);
    }
    .icon-2 {
      background-image: url(../../assets/images/python.png);
    }
    .icon-3 {
      background-image: url(../../assets/images/lamda.png);
    }
    .icon-4 {
      background-image: url(../../assets/images/automation.png);
    }
    .icon-5 {
      background-image: url(../../assets/images/uipath.png);
    }
    .icon-6 {
      background-image: url(../../assets/images/blueprism.png);
    }
    .icon-7 {
      background-image: url(../../assets/images/powerbi.png);
    }
    .icon-8 {
      background-image: url(../../assets/images/powerapps.png);
    }
    .icon-9 {
      background-image: url(../../assets/images/chatbots.png);
    }
  }
  .grid-3 {
    .icon-1 {
      background-image: url(../../assets/images/aws.png);
    }
    .icon-2 {
      background-image: url(../../assets/images/azure.png);
    }
    .icon-3 {
      background-image: url(../../assets/images/gcp.png);
    }
  }
  .row-separator {
    margin-top: 4rem;
  }

  .container {
    @include media-breakpoint-down(sm) {
      padding: 1rem !important;
    }
  }
}
