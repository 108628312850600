@import "../../mixins.scss";
.team {
  background: url(../../assets/images/team-bg.png) top center no-repeat;
  height: 565px;
  h1,
  h6 {
    color: white;
  }

  @include media-breakpoint-down(md) {
    height: 665px;
    background-position-x: -50rem;
  }
  .home-sub-title {
    font-size: 14px;
  }
  .explore-link {
    font-size: 15px;
    cursor: pointer;
  }
  .team-of-expert-sub-title {
    font-size: 1rem;
  }
}
