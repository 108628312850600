@import "../../mixins.scss";

.contact {
  h1,
  h3 {
    color: white;
  }

  h3 {
    padding: 0 10%;
  }
  .contact-button {
    @include round-button;
    text-decoration: none;
  }

  .contact-number {
    background: url(../../assets/images/contact-icon.svg) no-repeat left center;
    padding-left: 1.8rem;
    margin-top: 1rem;
    color: white;
    a,
    a:hover,
    a:active,
    a:focus {
      color: white;
      text-decoration: none;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 3rem;
  }
  .container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .get-in-touch-heading {
    font-weight: bold;
    font-size: calc(1.4rem + 1.8vw);
  }
}

.contact {
  padding-top: 11em !important;
}
.phone-number {
  text-align: center;
  margin-top: 20px;
}
