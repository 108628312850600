// 211666
@import "../../mixins.scss";

.resources {
  .resources-container {
    @include content-page-header;
    background-image: url(../../assets/images/resources_bg.png);

    @include media-breakpoint-down(lg) {
      background-position: 80% 0;
    }

    .resources-content {
      background-color: white;
    }
  }

  .about-content {
    .img-container {
      overflow: hidden;

      .img-fluid {
        transition: transform 0.55s;
      }

      .img-fluid:hover {
        transform: scale3d(1.1, 1.1, 1);
      }
    }
  }

  .about-content button {
    background: #ba5397 !important;
    color: white !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    cursor: pointer;
  }
  .case-study {
    color: #ba5397;
  }
  .case-study-desc {
    font-size: 8;
  }

  .case-study.text-align-right {
    text-align: right;
  }
}
.btn {
  background: #ba5397;
  color: white;
  border-radius: 20px;
  font-size: 12px;
}
.form-group {
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-control {
  background: #f9f9f9;
  font-size: 12px;
}
.cursor {
  cursor: pointer;
}
.viewCaseStudy {
  color: #ba5397;
  cursor: pointer;
  text-align: center;
  padding: 5px;
}
.grey-shade {
  color: #ccc;
}
card-group {
  margin-bottom: 10;
}

.transparent {
  opacity: 0.5;
}
.card-group > .card {
  min-height: 360px;
}
.download-link {
  color: #ba5397;
}
