@import "../../mixins.scss";
.about-container {
  @include content-page-header;
  background-image: url(../../assets/images/About.png);
}

.advantages {
  list-style: initial !important;
}
.about-content {
  background-color: white;
  .text-align-left {
    text-align: left !important;
    justify-content: left;
  }
  h3 {
    color: #54499d;
    text-align: center;
  }
  h5 {
    color: #ba5397;
  }
  .the-process-heading {
    justify-content: flex-start;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-top: 1rem;
  }
  .blue-big-text {
    @include media-breakpoint-up(lg) {
      padding-top: 3rem;
    }
    font-size: 16px;
    font-weight: 400;
    color: #54499d;
    padding-bottom: 1rem;
    text-align: left;
  }

  p {
    text-align: justify;
  }

  .block {
    background: rgba(106, 94, 187, 0.85);
    font-size: 13px;
    color: white;
    min-height: 400px;
    @include media-breakpoint-down(lg) {
      margin-bottom: 0.5rem;
      height: 19rem;
      padding: 0 3rem !important;
    }
    h3 {
      color: white;
      font-size: 18px;
      padding: 1rem 0;
      text-align: left;
      margin: 0;
    }
    p {
      line-height: 1.35rem;
      text-align: left;
    }
  }

  .block:nth-child(2),
  .carousel-item:nth-child(2) .block {
    background: #656acc;
    @include media-breakpoint-up(lg) {
      border-left: 2px solid white;
    }
  }

  .block:nth-child(3),
  .carousel-item:nth-child(3) .block {
    background: #ba5397;
    @include media-breakpoint-up(lg) {
      border-left: 2px solid white;
    }
  }

  .partner-img-container {
    width: 50%;
    margin: 0 auto;
  }

  .pink-big-text {
    font-size: 16px;
    color: #ba5397;
  }

  .advantage {
    background-image: url(../../assets/images/map-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    @include media-breakpoint-down(lg) {
      background-size: 90%;
    }

    background-position: center;

    .bottom-block-row {
      justify-content: space-between;
      @include media-breakpoint-down(lg) {
        justify-content: space-evenly;
      }
      @include media-breakpoint-down(xl) {
        justify-content: space-evenly;
      }
    }

    .bottom-block {
      position: relative;
      background-repeat: no-repeat;
      min-height: 25rem;
      background-size: contain;
      // background-image: url(../../assets/images/b1-bg.png);
      border: 0.1rem solid #ba5397;
      border-radius: 1rem;

      @include media-breakpoint-down(lg) {
        width: initial;
        background-size: cover;
        border-radius: 1rem;
        margin-bottom: 2rem;
      }

      @include media-breakpoint-up(xl) {
        width: 24%;
      }

      &.block-1,
      &.block-2 {
        @include media-breakpoint-down(xl) {
          margin-bottom: 2rem;
        }
      }

      background-position: center center;
      padding-bottom: 10px;

      &::after {
        position: absolute;
        content: "";
        width: 3rem;
        height: 3rem;
        left: calc(50% - 1.5rem);
        top: -1.5rem;
        background-size: contain;
      }
    }

    .block-1 {
      // background-image: url(../../assets/images/b1-bg.png);

      &::after {
        background-image: url(../../assets/images/skill.png);
      }
    }
    .block-2 {
      // background-image: url(../../assets/images/b2-bg.png);
      &::after {
        background-image: url(../../assets/images/virtual-team.png);
      }
    }
    .block-3 {
      // background-image: url(../../assets/images/b3-bg.png);
      &::after {
        background-image: url(../../assets/images/methodology.png);
      }
    }
    .block-4 {
      // background-image: url(../../assets/images/b4-bg.png);
      &::after {
        background-image: url(../../assets/images/multisector.png);
      }
    }
    color: black;

    h4 {
      font-size: 1.2rem;
      text-align: center;
      height: 7rem;
      padding-top: 2.5rem;
      padding-bottom: 2rem !important;
      border-bottom: 0.1rem solid #ba5397;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 1.75rem;
    }
  }

  .mobile-only {
    display: none;
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
    }
  }
  .desktop-only {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  // //small screen sizes
  // @media (max-width: 991px) {
  //   .mobile-only {
  //     display: block !important;
  //   }
  //   .desktop-only {
  //     display: none !important;
  //   }
  // }

  // //large resolutions only
  // @media (min-width: 1200px) {
  //   .mobile-only {
  //     display: none !important;
  //   }
  //   .desktop-only {
  //     display: block !important;
  //   }
  // }
}
.carousel-item {
  padding-top: 25px;
}
