@import "../../mixins.scss";

.footer {
  padding-top: 2rem !important;
  padding-bottom: 4rem !important;
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
  .footer-logo {
    width: 130px;
    @include media-breakpoint-down(md) {
      width: 150px;
    }
  }

  .social {
    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }
  }
  .social img {
    width: 2rem;
    margin-right: 0.5rem;
  }
  .block-1 {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      display: inline;
    }
  }
  .copyright {
    color: white;
    padding-top: 1rem;
    font-size: 11px;
  }
  .design-developed {
    font-size: 9px;
  }

  .footer-block {
    h4 {
      color: #ba5397;
      font-size: 16px;
      font-weight: bold;
    }
    ul {
      padding-left: 0;
    }
    li {
      color: white;
      list-style: none;
      line-height: 1.6rem;
      cursor: pointer;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
