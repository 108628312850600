@import "../../mixins.scss";
.contact-us {
  .contact-container {
    padding: 3rem;
    padding-top: 8.5rem;
    @include content-page-header;
    background: #211666;
    background-image: url(../../assets/images/contact-bg.png);
  }
  h1,
  h3 {
    color: white;
  }

  h3 {
    padding: 0 10%;
  }
  .contact-button {
    padding: 10px;
    border: 1px white solid;
    color: white;
    width: 250px;
    border-radius: 20px;
    text-align: center;
  }

  .container {
    background: white !important;
  }
  .form-group {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .form-control {
    background: #f9f9f9;
    font-size: 12px;
  }
  .btn {
    background: #ba5397;
    color: white;
    border-radius: 20px;
    font-size: 12px;
  }
  .google-map {
    padding: 0px;
  }
}
