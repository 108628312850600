@import "../../mixins.scss";
.privacy-container {
  padding: 40px;
  @include content-page-header;
  background-image: url(../../assets/images/About.png);
}

.advantages {
  list-style: none;
}
.privacy-content {
  background-color: white;

  b {
    font-weight: 500;
    font-size: 18px;
    color: rgb(84, 73, 157);
  }

  h3 {
    color: #54499d;
    text-align: center;
  }
  .blue-big-text {
    font-size: 16px;
    color: #54499d;
    padding-bottom: 1rem;
  }

  .block {
    background: rgba(106, 94, 187, 0.85);
    font-size: 13px;
    color: white;
    @include media-breakpoint-down(lg) {
      margin-bottom: 0.5rem;
    }
    h3 {
      color: white;
      font-size: 18px;
      padding: 1rem 0;
      text-align: left;
      margin: 0;
    }
    p {
      line-height: 1.35rem;
    }
  }

  .block:nth-child(2) {
    background: #656acc;
    @include media-breakpoint-up(lg) {
      border-left: 2px solid white;
    }
  }

  .block:nth-child(3) {
    background: #ba5397;
    @include media-breakpoint-up(lg) {
      border-left: 2px solid white;
    }
  }

  .partner-img-container {
    width: 50%;
    margin: 0 auto;
  }

  .pink-big-text {
    font-size: 16px;
    color: #ba5397;
  }

  .advantage {
    background-image: url(../../assets/images/map-bg.png);
    background-repeat: no-repeat;
    background-size: contain;

    .bottom-block-row {
      @include media-breakpoint-down(lg) {
        justify-content: space-evenly;
      }
    }

    .bottom-block {
      position: relative;
      background-repeat: no-repeat;
      min-height: 25rem;
      background-size: contain;
      @include media-breakpoint-down(lg) {
        background-size: cover;
        border-radius: 1rem;
        margin-bottom: 2rem;
      }

      background-position: center center;
      padding-bottom: 10px;

      &::after {
        position: absolute;
        content: "";
        width: 3rem;
        height: 3rem;
        left: calc(50% - 1.5rem);
        top: -1.5rem;
        background-size: contain;
      }
    }

    .block-1 {
      background-image: url(../../assets/images/b1-bg.png);

      &::after {
        background-image: url(../../assets/images/skill.png);
      }
    }
    .block-2 {
      background-image: url(../../assets/images/b2-bg.png);
      &::after {
        background-image: url(../../assets/images/virtual-team.png);
      }
    }
    .block-3 {
      background-image: url(../../assets/images/b3-bg.png);
      &::after {
        background-image: url(../../assets/images/methodology.png);
      }
    }
    .block-4 {
      background-image: url(../../assets/images/b4-bg.png);
      &::after {
        background-image: url(../../assets/images/multisector.png);
      }
    }
    color: white;

    h4 {
      font-size: 1.2rem;
      text-align: center;
      height: 7rem;
      padding-top: 2.5rem;
      padding-bottom: 2rem !important;
      border-bottom: 1px solid white;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 1.75rem;
    }
  }
}
