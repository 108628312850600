@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

@mixin content-page-header {
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 3rem;
  padding-top: 10rem;
  position: relative;
  @include media-breakpoint-down(lg) {
    padding: 1rem;
    padding-top: 10rem;
    background-position: 50% 0;
  }

  h1 {
    color: white;
  }

  h5 {
    color: white;

    font-size: 16px;
  }
}

@mixin round-button {
  padding: 10px;
  border: 1px white solid;
  color: white;
  width: 250px;
  border-radius: 20px;
  text-align: center;
}
