// 211666
@import "../../mixins.scss";
.services-container {
  @include content-page-header;
  background: #211666;
  background-image: url(../../assets/images/services-bg.png);
}

h1 {
  font-weight: bold;
}

.services-content {
  b {
    color: #54499d;
  }
  background-color: white;
  padding-top: 50px;
  .cells {
    justify-content: center;
    .col-12 {
      background: #ba5397;
      margin-right: 2px;
      color: white;
      display: flex;
      justify-content: left;
      align-items: left;
      padding-top: 1.2rem !important;
      padding: 1.95rem;
      text-align: left;
    }

    .active {
      background: white;
      border: 1px solid #ba5397;
      color: #ba5397;
    }
  }
  .block {
    .row {
      justify-content: space-between;
    }
    .left {
      h3 {
        font-size: 18px;
        color: #ba5397;
      }
    }
    .right {
      h3 {
        font-size: 22px;
        color: #54499d;
      }
    }
  }
  .active a {
    color: #ba5397 !important;
  }
  .services-margin {
    margin-top: 10px;
  }
}
.nav-link {
  color: white;
  cursor: pointer;
  background-color: #ba5397 !important;
  padding: 10px;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: white !important;
  padding: 10px;
  color: #ba5397 !important;
}
.services-content .nav-tabs .nav-link {
  padding: 30px !important;
}
.services-content .nav-item {
  border-right: 1px white solid;
}

.services-content .nav-tabs {
  justify-content: center;
}

@media (max-width: 991px) {
  .services-content .nav-tabs {
    padding-top: 0px;
    justify-content: center;
    border-right: 0px white solid;
    border-bottom: 1px rgba(0, 0, 0, 0.151) solid;
  }

  .services-content {
    padding-top: 0px;
  }
  .services-content .nav-item {
    width: 100%;
    border-bottom: 1px white solid;
  }
  .services-content .nav-item button {
    width: 100%;
  }
  .services-content .nav-tabs .nav-link {
    padding: 10px !important;
  }

  .rmc-tabs-tab-bar-top .rmc-tabs-tab-bar-tab,
  .rmc-tabs-tab-bar-bottom .rmc-tabs-tab-bar-tab {
    padding: 16px 0;
    padding-left: 4px !important;
  }
}
.rmc-tabs-tab-bar-tab {
  cursor: pointer;
}
.rmc-tabs-tab-bar-tab-active {
  color: #ba5397 !important;
}
.rmc-tabs-tab-bar-underline {
  border: 1px #54499d solid !important;
  background-color: #54499d;
}
.tab-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: nowrap;
}
.rmc-tabs-pane-wrap {
  min-height: 550px !important;
}
